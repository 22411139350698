<template>
  <div>
    <v-text-field v-model="localeValue"
                  type="number"
                  :disabled="isReadSurvey || options.readonly || nc"
                  :min="options.min !== undefined ? options.min : null"
                  :max="options.max !== undefined ? options.max : null"
                  :rules="[integer.rules.isValid, integer.rules.min, integer.rules.max]"
                  @focusout="onUpdateValue(localeValue)"
    />
    <div class="switches">
      <v-switch :label="$t('survey.slider.nc')"
                v-if="!options.mandatory"
                v-model="nc"
                :disabled="isReadSurvey || na"
                class="switch"
      />
      <v-switch :label="$t('survey.slider.na')"
                v-if="!options.mandatory"
                v-model="na"
                :disabled="isReadSurvey || nc"
                class="switch"
      />
    </div>
  </div>
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'
import numberMixin from '@/components/Survey/Reader/numberMixin'
import ncMixin from '@/components/Survey/Reader/ncMixin'
import naMixin from '@/components/Survey/Reader/naMixin'

export default {
  name: 'SurveyQuestionInteger',

  mixins: [questionMixins, numberMixin, ncMixin, naMixin],

  data: function () {
    return {
      error: '',
      integer: {
        rules: {
          isValid: v => {
            if (this.canBeEmpty(v)) return true
            if (this.nc) return ''

            if (v === null) {
              return this.$t('survey.integer.invalid')
            }

            if (!/^[0-9]+$/.test(v)) {
              return this.$t('survey.integer.invalid')
            }

            return !isNaN(parseInt(v)) || this.$t('survey.integer.invalid')
          },
          min: v => this.options.min === undefined || v >= this.options.min || this.canBeEmpty(v) || this.$t('survey.integer.invalid'),
          max: v => this.options.max === undefined || v <= this.options.max || this.canBeEmpty(v) || this.$t('survey.integer.invalid')
        },
      },
    }
  },
  methods: {
    canBeEmpty(v) {
      let empty = [undefined, null, "", "NC", "NA"].indexOf(v) !== -1
      let isMandatory = this.options.mandatory !== undefined && this.options.mandatory === true

      return empty && !isMandatory
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.switches > * {
  display: inline-flex;
  margin-right: 20px;
}

</style>